import React from "react";
import { Layout } from "../components/layout";

const TemoignagesPage = () => {
  return (
    <Layout>
      <h1>Temoignages</h1>
    </Layout>
  );
};

export default TemoignagesPage;

